/**
 * Colors
 */
$color-greyscale-1: #ffffff; /* Greyscale 1*/
$color-greyscale-2: #f7f8fb; /* Greyscale 2*/
$color-greyscale-3: #e8ebf2; /* Greyscale 3*/
$color-greyscale-4: #dce0e9; /* Greyscale 4*/
$color-greyscale-5: #c3c9d7; /* Greyscale 5*/
$color-greyscale-6: #b3b9c7; /* Greyscale 6*/
$color-greyscale-7: #47535f; /* Greyscale 7*/
$color-greyscale-8: #333a41; /* Greyscale 8*/
$color-greyscale-9: #000000; /* Greyscale 9*/

$color-primary-light: #97dfff; /* Primary Light Color */
$color-primary-main: #58ccff; /* Primary Main Color */
$color-primary-dark: #47baee; /* Primary Dark Color */

$color-secondary-light: #ffe297; /* Secondary Light Color */
$color-secondary-main: #ffb258; /* Secondary Main Color */
$color-secondary-dark: #ee8347; /* Secondary Dark Color */

$color-success-light: #99ff97; /* Success Light Color */
$color-success-medium: #58ff87; /* Success Medium Color */
$color-success-dark: #47ee76; /* Success Dark Color */

$color-warning-light: #ffe297; /* Secondary Light Color */
$color-warning-medium: #ffb258; /* Secondary Main Color */
$color-warning-dark: #ee8347; /* Secondary Dark Color */

$color-error-light: #f9d6d6; /* Warning Light Color */
$color-error-medium: #ff5858; /* Warning Medium Color */
$color-error-dark: #ee4747; /* Warning Dark Color */

$color-text-light: $color-greyscale-7; /* Text Light Color */
$color-text-medium: $color-greyscale-8; /* Text Medium Color */
$color-text-dark: $color-greyscale-9; /* Text Dark Color */

$color-background-light: $color-greyscale-1; /* Background Light Color */
$color-background-medium: $color-greyscale-2; /* Background Medium Color */
$color-background-dark: $color-greyscale-9; /* Background Dark Color */

$color-border-light: $color-greyscale-3; /* Border Light Color */
$color-border-medium: $color-greyscale-6; /* Border Medium Color */
$color-border-dark: $color-greyscale-7; /* Border Dark Color */

/**
  * Sizes
  */
$default-rem-base-size: 8px;
$default-font-size: 2rem;
$default-line-height: 3rem;
$default-font-weight: 400;
$default-border-size: 0.125rem;

/**
  * Spacing
  */
$space-xxs: 1rem;
$space-xs: 2rem;
$space-s: 3rem;
$space-m: 4rem;
$space-l: 5rem;
$space-xl: 6rem;
$space-xxl: 8rem;

$default-text-margin-bottom: $space-xxs;
$default-gutter-space: $space-m;

/*
  * Border
 */
$default-border-color: $color-border-medium;
$border-xxs-value: $default-border-size solid $default-border-color;

/**
   * Shadows
   */
$shadow-2: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
$shadow-6: 0 0.375rem 0.75rem rgba(0, 0, 0, 0.2);
$shadow-12: 0 0.75rem 1.5rem rgba(0, 0, 0, 0.2);
$shadow-24: 0 1.5rem 3rem rgba(0, 0, 0, 0.2);

/**
  * Animation
  */
$default-animation-timing-function: "ease";
$default-animation-duration: 0.4s;

/**
  * Responsive
  */

/* This will need to include the padding from .container, e.g.
  if 1240px is the site width, add the left/right padding (24px + 24px) = 
  1288px = 161rem */
$site-container-width: 161rem;

$mq-lg-max: "(max-width: 64em)"; /* Desktop First MQ - 1024px */
$mq-md-max: "(max-width: 50em)"; /* Desktop First MQ - 800px */
$mq-sm-max: "(max-width: 31em)"; /* Desktop First MQ - 496px */

$mq-lg-min: "(min-width: 64em)"; /* Mobile First MQ - 1024px */
$mq-md-min: "(min-width: 50em)"; /* Mobile First MQ - 800px */
$mq-sm-min: "(min-width: 31em)"; /* Mobile First MQ - 496px */

/**
  * Images
  */
$img-loading: "/assets/images/loading.gif";
$img-select-arrow: "/assets/images/select-dropdown.svg";
