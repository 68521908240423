@import "../Resources/variables";
@import "../Resources/mixins";
@import "./type";
button {
  &.v-button-clear-styling {
    appearance: none;
    background-color: transparent;
    border: 0;
  }
}

//Base Button
.ant-popover-buttons button,
.a-button {
  display: inline-block;
  position: relative;
  width: auto;
  min-height: 6rem;
  padding: $space-xs;
  @include font(2rem, 2rem);
  font-weight: 500;
  cursor: pointer;
  border: $default-border-size solid;
  border-radius: 0.5rem;
  text-align: center;
  justify-content: center;
  @include tr();

  &:disabled,
  &[disabled] {
    pointer-events: none;
  }

  &:not(.v-button-full):not(.v-button-icon-right):not(.v-button-icon-left):not(.v-button-icon) {
    min-width: 18rem;
  }

  @media #{$mq-sm-max} {
    width: 100%;
    min-width: 0;
  }

  &.v-button-warning {
    color: $color-text-light;
    background-color: $color-warning-light;
    border-color: $color-border-medium;

    &:hover {
      color: $color-greyscale-7;
      text-align: center;
      border: 1px solid $color-warning-light;
    }

    &:active {
      color: $color-greyscale-1;
      background-color: $color-warning-medium;
      text-align: center;
      border: 1px solid $color-warning-medium;
    }
  }

  &.v-button-danger {
    color: $color-text-light;
    background-color: $color-error-light;
    border-color: $color-border-medium;

    &:hover {
      color: $color-greyscale-7;
      text-align: center;
      border: 1px solid $color-error-light;
    }

    &:active {
      color: $color-greyscale-1;
      background-color: $color-error-medium;
      text-align: center;
      border: 1px solid $color-error-medium;
    }
  }

  &.v-button-success {
    color: $color-text-light;
    background-color: $color-success-light;
    border-color: $color-border-medium;

    &:hover {
      color: $color-greyscale-7;
      text-align: center;
      border: 1px solid $color-success-light;
    }

    &:active {
      color: $color-greyscale-1;
      background-color: $color-success-medium;
      text-align: center;
      border: 1px solid $color-success-medium;
    }
  }
}

.ant-popover-buttons button {
  min-width: unset !important;
}

//Text Base Button
.a-text-button {
  display: inline-flex;
  width: auto;
  text-align: left;
  color: $color-primary-main;
  border-bottom: 0.125rem solid $color-primary-main;
  @include tr();

  &.v-active,
  &:hover {
    color: $color-greyscale-7;
    border-bottom-color: $color-greyscale-7;
    @include tr();
  }
}

//Primary Button
.v-button-primary {
  color: $color-greyscale-7;
  background-color: $color-greyscale-1;
  text-align: center;
  border: 1px solid $color-primary-dark;

  &:hover {
    background-color: $color-primary-light;
    box-shadow: $shadow-24;
    @include tr();
  }

  &:active {
    color: $color-greyscale-1;
    background-color: $color-primary-dark;
    border-color: $color-primary-dark;
    box-shadow: none;
  }

  &:disabled,
  &[disabled] {
    color: $color-text-light;
    background-color: $color-greyscale-4;
    border-color: $color-greyscale-4;
  }

  &.v-button-loading {
    &:before {
      color: $color-greyscale-1;
    }
  }
}

//Secondary Button
.v-button-secondary {
  color: $color-text-light;
  background-color: $color-greyscale-1;
  border-color: $color-border-medium;

  &:hover {
    color: $color-text-light;
    background-color: $color-greyscale-2;
    box-shadow: $shadow-24;
    @include tr();
  }

  &:active {
    color: $color-text-light;
    background-color: $color-greyscale-1;
    border-color: $color-border-medium;
    box-shadow: none;
  }

  &:disabled,
  &[disabled] {
    color: $color-text-light;
    background-color: $color-greyscale-4;
    border-color: $color-greyscale-4;
  }

  &.v-button-loading {
    &:before {
      color: $color-text-light;
    }
  }
}

.v-button-loading {
  color: transparent;
  pointer-events: none;
  @include tr();
  @include loading-adjust-huener();
}

.v-button-icon {
  i {
    margin: 0;
  }
}

.v-button-icon-right {
  i {
    margin-left: 2rem;
    line-height: 2rem;
  }
}

.v-button-icon-left {
  i {
    margin-right: 2rem;
    line-height: 2rem;
  }
}

.v-button-full {
  width: 100%;
}

.v-button-center {
  display: table;
  width: auto;
  margin: 0 auto;
}

//Search button
.a-search-keyword-button {
  width: 100%;
  border: 0;
  background-color: $color-primary-main;
  color: $color-greyscale-1;
  padding: $space-s;
  text-align: left;
  @include tr();

  &:hover {
    @include tr();
    background-color: $color-primary-dark;
    color: $color-greyscale-1;
  }
}
