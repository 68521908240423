@mixin tr(
  $args: all $default-animation-timing-function $default-animation-duration
) {
  transition: $args;
}

@mixin shift-up() {
  transform: translate(0, -50%);
}

@mixin shift-left() {
  transform: translate(-50%, 0);
}

@mixin shift-both() {
  transform: translate(-50%, -50%);
}

@mixin shift-none() {
  transform: translate(0%, 0%);
}

@mixin center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@mixin text-truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin absolute-center() {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@mixin fill-area() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

@mixin bg-cover() {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin bg-contain() {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin bg-center($bg-size: 3rem) {
  background-position: center;
  background-repeat: no-repeat;
  background-size: $bg-size;
}

@mixin loading-adjust-huener() {
  &:before {
    content: "\f110";
    opacity: 1;
    @include font-awesome-family();
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    font-size: 3rem;
    z-index: 1;
    @include tr();
    animation: adjust-hue 0.8s linear infinite;
  }
}

@mixin module-style() {
  background-color: $color-greyscale-1;
  border: $default-border-size solid $color-border;
}
