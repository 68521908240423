@import "../Resources/variables";

.o-content {
  padding-top: 15rem;
  margin-bottom: 15rem;
}
.o-section {
}

.o-hero-wrapper {
  width: 100% !important;
  padding: 6rem 0;
  margin-top: -15rem;
  min-height: 400px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  width: calc(100vw - 6rem);
  .content {
    background: rgba(255, 255, 255, 0.5);
    float: left;
    padding: 3rem;

    h1 {
      margin: 0;
    }

    i {
      font-size: 2rem;
      display: block;
      font-weight: bold;
    }
  }
}

.o-legal-content {
  li {
    padding: 1rem;
  }
}

.dropdown-container{
  margin-top: 1rem;
  padding: 3rem;
  border: 2px solid $color-greyscale-3;
  background-color: #fff;
  width: 100%;
  a{
    color: black;
  }
}

// .site-layout, .ant-layout-content{
//   background-color: #fff;
// }