@import "../Resources/variables";
footer {
  &.o-footer {
    background: #001529;
    padding-top: 8rem;
    padding-bottom: 3rem;
    padding-left: 8vw;
    padding-right: 10vw;

    color: #f0f2f5;
  }
}

.footer-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.footer-li {
  list-style-type: none;
  color: white;
  font-size: 1.8rem;

  a {
    color: white;

    &:hover {
      color: #51c5ff;
    }
  }

  &:hover {
    color: #51c5ff;
  }
}

.footer-pipe:after {
  content: "|";
  padding-right: 1rem;
  padding-left: 1rem;
  color: #51c5ff;
}

@media screen and (max-width: 700px) {
  .footer-list {
    flex-direction: column;
    align-items: center;
  }

  .footer-li {
    padding: 0.5rem;
  }

  .footer-pipe:after {
    display: none;
  }
}
