﻿.m-input {
  width: 100%;
  input,
  select,
  textarea,
  .a-input-file-label {
    margin-bottom: 0;
  }
}

.m-label-input {
  width: 100%;
  // margin-bottom: $space-s;

  .m-input {
    margin-bottom: 0;
  }
}

.v-input-error {
  input,
  select,
  textarea,
  .a-input-file-label {
    margin-bottom: 1rem;
  }

  label.a-error-type {
    display: block;
    margin-bottom: 0;
  }
}

.v-input-icon {
  width: 100%;
  position: relative;

  button {
    position: absolute;
    top: 0;
    height: 100%;
    width: 5rem;
  }

  i {
    float: left;
    height: 2rem;
    position: absolute;
    font-size: 2rem;
    top: 0;
    bottom: 0;
    left: 2rem;
    margin: auto;
  }

  &.v-right {
    i {
      float: right;
      left: auto;
      right: 2rem;
      padding-right: 0;
    }

    button {
      right: 0;
    }

    input {
      padding-right: 5rem;

      &:not(.no-focus):focus {
        padding-right: 5rem;
      }
    }
  }

  &:not(.v-right) {
    i {
      left: 2rem;
    }

    button {
      left: 0;
    }

    input {
      padding-left: 5rem;

      &:not(.no-focus):focus {
        padding-right: 5rem;
      }
    }
  }
}
